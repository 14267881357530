<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="content-box">
        <div class="title">{{ $t('settlement.title') }}</div>
        <div class="type-box" :style="{width: (this.$i18n.locale === 'zh' ? '400px': '200px')}">
          <div :class="{ active: type === 1 }" @click="type = 1">{{ $t('settlement.button_send_me') }}</div>
          <div :class="{ active: type === 2 }" @click="type = 2" v-if="this.$i18n.locale === 'zh'">
            {{ $t('settlement.button_send_shop') }}
          </div>
        </div>
        <div class="price-box">
          {{ $t('settlement.total_title') }}
          <span style="font-family: 'ELEMENT-ICONS'">{{ total }}</span
          > {{ $t('settlement.total_title_end') }}
        </div>
        <div class="line"></div>
        <div class="shop-box">
          <div class="shop-list">
            <h2>{{ $t('settlement.goods') }}</h2>
            <div v-for="(item, shopIdx) in shopList" :key="item.id">
              <template v-if="!isRim">
                <template v-for="(attr, idx) in item.attrList">
                  <div :key="attr.id" v-if="attr.active" class="shop-item">
                    <img :src="item.img"/>
                    <div>
                      <span @click="calculate('sub', shopIdx, idx)">-</span>
                      <span style="color: #fff">x{{ attr.num }}</span>
                      <span @click="calculate('add', shopIdx, idx)">+</span>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="isRim">
                <template v-if="item.attrList.length <=2">
                  <template v-for="(attr, idx) in item.attrList">
                    <div :key="attr.id" v-if="attr.active" class="shop-item">
                      <img :src="item.img"/>
                      <div>
                        <span @click="calculate('sub', shopIdx, idx)">-</span>
                        <span style="color: #fff">x{{ attr.num }}</span>
                        <span @click="calculate('add', shopIdx, idx)">+</span>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="item.attrList.length > 2">
                  <template v-for="(attr,idx) in item.attrList">
                    <template v-for="(attrInner,idxc) in attr.innerList">
                      <div :key="attrInner.id" v-if="attrInner.active" class="shop-item">
                        <img :src="item.img"/>
                        <div>
                          <span @click="calculateInner('sub', shopIdx, idx, idxc)">-</span>
                          <span style="color: #fff">x{{ attrInner.num }}</span>
                          <span @click="calculateInner('add', shopIdx, idx, idxc)">+</span>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </div>
          </div>
          <div class="shop-text">
            <div style="font-size: 18px">
              <img src="https://www.achencybe.com/img/pc/logo.png"/>
              {{ $t('settlement.shop_text.text_1') }}{{ titleType === '锻造轮辋' ? 'Forged Wheel' : titleType }}{{ $t('settlement.shop_text.text_2') }}
            </div>
            <div class="border">
              <div>
                <!-- style="text-decoration: underline;"  -->
                {{ $t('settlement.shop_text.text_3') }}
                <a @click="$router.push({ name: 'CarEPlusPage' })"
                >{{ $t('settlement.shop_text.text_4') }}</a
                >
              </div>
              <div style="margin-top: 20px">
                {{ $t('settlement.shop_text.text_5') }}
                <a @click="$router.push({ name: 'ClubPage' })"
                >{{ $t('settlement.shop_text.text_6') }}</a
                >
              </div>
            </div>
            <div class="border" style="padding-top: 50px">
              {{ $t('settlement.border.text_1') }}<span>{{ stringFormat(clientAdderss) }}</span>
              <div class="time-box" style="font-size: 18px; line-height: 32px">
                {{ $t('settlement.border.text_2') }}{{
                  $route.query.completionTime
                }}{{ $t('settlement.border.text_3') }}
              </div>
              {{ $t('settlement.border.text_4') }}<br/>
              {{ $t('settlement.border.text_5') }}
            </div>
          </div>
        </div>
        <div class="line"></div>

        <div class="address-box">
          <template v-if="type === 1">
            <div class="address-title">{{ $t('settlement.address_box.title') }}</div>
            <div>
              <!--              <form action="1111">-->
              <div class="address-con">
                <div class="address-left">
                  <div style="font-size: 18px">{{ $t('settlement.address_box.title_placeholder') }}</div>
                  <div
                      class="ipt-box"
                      style="cursor: pointer"
                      @click="addAddress"
                      v-if="!address.length"
                  >
                    {{ $t('settlement.address_box.add_address') }}
                  </div>
                  <button
                      @click="addressClick"
                      class="address-btn"
                      v-if="address.length"
                  >
                    {{
                      isEdit ? this.$t('settlement.address_box.save_address') : this.$t('settlement.address_box.edit_address')
                    }}
                  </button>
                  <div class="tips-box">
                    <div style="margin-bottom: 20px">{{ $t('settlement.tips.text_1') }}</div>
                    <div>
                      {{ $t('settlement.tips.text_2') }}
                    </div>
                    <div>
                      {{ $t('settlement.tips.text_3') }}
                    </div>
                    <div>{{ $t('settlement.tips.text_4') }}</div>
                  </div>
                </div>
                <div class="address-right">
                  <template v-if="address.length">
                    <template v-if="isEdit">
                      <input
                          type="text"
                          name="name"
                          v-model="form.name"
                          :placeholder="this.$t('settlement.address_placeholder.name')"
                      />
                      <el-cascader
                          :options="cityList"
                          v-model="form.city"
                          filterable
                          :props="{
                          value: 'label',
                        }"
                          :placeholder="this.$t('settlement.address_placeholder.city')"
                      ></el-cascader>
                      <input
                          type="text"
                          name="address"
                          v-model="form.address"
                          :placeholder="this.$t('settlement.address_placeholder.address')"
                      />
                      <input
                          type="text"
                          name="phone"
                          v-model="form.phone"
                          :placeholder="this.$t('settlement.address_placeholder.phone')"
                      />
                    </template>
                    <template v-else>
                      <div
                          class="ipt-box"
                          style="
                          margin-left: -100px;
                          margin-top: 0px;
                          width: 500px;
                          cursor: pointer;
                          padding: 10px 20px 20px;
                        "
                          v-for="item in address"
                          :key="item.id"
                          @click="form = item"
                      >
                        <div style="font-size: 18px">
                          <h6
                              style="
                              font-size: 14px;
                              color: #eee;
                              font-weight: 100;
                              text-align: left;
                              margin-bottom: 16px;
                            "
                          >
                            {{ $t('settlement.ipt_box.title') }}
                          </h6>
                          <div
                              style="
                              font-size: 18px;
                              text-align: left;
                              line-height: 1.6;
                            "
                          >
                            {{ item.name }}
                          </div>
                          <div
                              style="
                              font-size: 18px;
                              text-align: left;
                              line-height: 1.6;
                            "
                          >
                            {{ item.address }}
                          </div>
                          <div
                              style="
                              font-size: 18px;
                              text-align: left;
                              line-height: 1.6;
                            "
                          >
                            {{ item.phone }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div
                        style="
                        margin-left: -96px;
                        color: #535353;
                        font-weight: 100;
                        margin-top: 20px;
                      "
                    >
                      {{ $t('settlement.ipt_box.title_none') }}
                    </div>
                  </template>
                </div>
              </div>
              <!--              </form>-->
            </div>
          </template>
          <template v-else>
            <div class="address-title">{{ $t('settlement.shop.shop_title') }}</div>
            <div>
              <form action="1111">
                <div class="address-con">
                  <div class="address-left">
                    <div style="font-size: 18px; margin-bottom: 27px">
                      {{ $t('settlement.shop.shop_search') }}
                    </div>
                    <el-cascader
                        :options="merchantsCityList"
                        v-model="cascaderVal"
                        filterable
                        :props="{
                        value: 'id',
                      }"
                        :placeholder="this.$t('settlement.shop.city')"
                        @change="getFacilitatorList"
                    ></el-cascader>
                    <el-scrollbar style="width: 373px; height: 420px;">
                      <div
                          class="ipt-box ipt-checkbox"
                          style="width: 353px; cursor: pointer;margin:20px 0"
                          v-for="item in merchantsList"
                          :key="item.id"
                          :class="{ 'is-active': activeMerchants.id === item.id }"
                          @click="activeMerchants = item"
                      >
                        <div style="font-size: 18px">
                          {{ item.name }}
                          <div style="font-size: 14px; text-align: left">
                            ——{{ item.region }}
                          </div>
                        </div>
                        <div style="font-size: 18px">
                          {{ item.type === 0 ? $t('settlement.shop.support.free') : $t('settlement.shop.support.pay') }}
                        </div>
                      </div>
                    </el-scrollbar>
                  </div>
                  <div class="address-right">
                    <div
                        style="
                        text-align: left;
                        line-height: 1.8;
                        width: 380px;
                        font-size: 18px;
                      "
                    >
                      <div>{{ activeMerchants.name }}</div>
                      <div>{{ activeMerchants.address }}</div>
                      <div style="margin-bottom: 20px">
                        {{ activeMerchants.city }}
                      </div>
                      <div>{{ activeMerchants.tel }}</div>
                      <div>
                        {{ activeMerchants.week }}
                      </div>
                      <div style="margin-bottom: 20px">
                        {{ activeMerchants.timer }}
                      </div>
                      <div
                          style="
                          line-height: 1.4;
                          font-size: 16px;
                          margin-bottom: 20px;
                          font-weight: 100;
                        "
                      >
                        {{ $t('settlement.shop.alert.text_1') }}
                      </div>
                      <div
                          style="
                          line-height: 1.4;
                          font-size: 16px;
                          font-weight: 100;
                        "
                      >
                        {{ $t('settlement.shop.alert.text_2') }}
                      </div>
                    </div>
                    <div
                        class="tips-box"
                        style="text-align: left; width: 380px; margin-top: 20px"
                    >
                      <div style="margin-bottom: 20px">{{ $t('settlement.shop.alert.text_3') }}</div>
                      <div>
                        {{ $t('settlement.shop.alert.text_4') }}
                      </div>
                      <div>
                        {{ $t('settlement.shop.alert.text_5') }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </div>
        <div class="line"></div>
        <div class="buy-box">
          <div>{{ $t('settlement.footer.title') }}</div>
          <button @click="toGo">{{ $t('settlement.footer.button') }}</button>
        </div>
      </div>
      <zui-footer/>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import is from "element-ui/src/locale/lang/is";

const cname = returnCitySN.cname;
import {stringFormat} from '@/utils/stringFormat'

const dataCity = require("@/assets/city.json");
dataCity.sort((a, b) => {
  const remarkA = a.remark.toUpperCase(); // 忽略大小写
  const remarkB = b.remark.toUpperCase(); // 忽略大小写
  if (remarkA < remarkB) {
    return -1;
  }
  if (remarkA > remarkB) {
    return 1;
  }
  // name 必须相等
  return 0;
})
dataCity.unshift(
    {
      label: "Global",
      remark: "Global",
      level: "1",
      code: "0000",
      children: [
        {
          label: "Global",
          level: "1",
          code: "0001"
        }
      ]
    })
console.log(dataCity)
export default {
  components: {Page, ZuiHeader, ZuiFooter},
  computed: {
    is() {
      return is
    },
    cartype() {
      return this.$route.query.cartype
          ? this.$route.query.cartype
          : "carlistM3";
    },
    titleType() {
      const option = {
        carlistBMW3: "BMW THE 3",
        carlistX3: "BMW THE X3",
        carlistM3: "BMW M3/M4",
        carlistTHE4: "BMW THE 4",
        carlistTHE5: "BMW THE 5/M5",
        carlistchallenger: "Challenger SRT",
        rim: "锻造轮辋"
      };
      return option[this.$route.query.cartype]
    },
    total() {
      let total = 0;
      if (this.shopList.length) {
        this.shopList.forEach((item) => {
          if (this.isRim) {
            item.attrList.forEach((attr) => {
              if (attr.active) {
                let price = this.$i18n.locale === 'zh' ? attr.price : attr.price_dollar;
                if (price == null || price === 'null') {
                  price = "0"
                }
                total += price * attr.num;
              }
              let innerTotal = 0;
              if (attr.innerList) {
                attr.innerList.forEach((attrInner) => {
                  if (attrInner.active) {
                    let price = this.$i18n.locale === 'zh' ? attrInner.price : attrInner.price_dollar;
                    if (price == null || price === 'null') {
                      price = "0"
                    }
                    innerTotal += price * attrInner.num;
                  }
                });
                total += innerTotal;
              }
            });
          } else {
            item.attrList.forEach((attr) => {
              if (attr.active) {

                let price = this.$i18n.locale === 'zh' ? attr.price : attr.price_dollar;
                if (price == null || price === 'null') {
                  price = "0"
                }
                total += price * attr.num;
              }
            });
          }
        });
      }
      return total.toString().replace(/\d+/, function (n) {
        return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },
    clientAdderss() {
      let result = cname;
      if (
          this.$store.state.userInfo &&
          this.type === 1 &&
          this.$store.state.userInfo.deliveryCityName
      ) {
        result = this.$store.state.userInfo.deliveryCityName.split("/")[1];
      } else if (this.type === 2) {
        result = this.activeMerchants.city;
      }
      return result;
    },
  },
  watch: {
    "$store.state.userInfo": {
      handler(userInfo) {
        this.form = {
          address: userInfo.deliveryAddress,
          name: userInfo.deliveryName,
          city:
              userInfo.deliveryCityName && userInfo.deliveryCityName.split("/"),
          phone: userInfo.deliveryMobile,
        };
        if (
            Object.keys(this.form).some((key) => {
              return !!this.form[key];
            })
        ) {
          this.form.id = userInfo.id;
          this.address = [];
          this.address.push(this.form);
        } else {
          this.form.id = userInfo.id;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.cartype === 'rim') {
      this.isRim = true
    }
    if (window.localStorage.getItem("activeShopList")) {
      this.shopList = JSON.parse(window.localStorage.getItem("activeShopList"));
      this.activeMerchants = this.merchantsList[0];
    } else {
      alert("请先选择商品");
    }
    this.getCity();
  },
  data() {
    return {
      isRim: false,
      type: 1,
      isEdit: false,
      form: {
        id: "",
        address: "",
        name: "",
        city: "",
        phone: "",
      },
      cascaderVal: [0, 0],
      shopList: [],
      address: [],
      cityList: dataCity,
      merchantsCityList: [],
      merchantsList: [],
      activeMerchants: {},
    };
  },
  methods: {
    stringFormat,
    formatPrice(val) {
      return val.toString().replace(/\d+/, function (n) {
        return "￥" + n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },
    async toGo() {
      let hasaAddress = true;
      if (this.type == "1") {
        Object.values(this.form).forEach((item) => {
          if (!item) {
            hasaAddress = false;
          }
        });
      } else {
        if (!Object.keys(this.activeMerchants).length) {
          hasaAddress = false;
        }
      }

      if (hasaAddress) {
        window.localStorage.removeItem("facilitatorId");
        let shopCar = this.shopList.map((item) => {
          return item.attrList;
        });
        shopCar = shopCar.flat(Infinity);
        console.log(shopCar)
        window.localStorage.setItem("shopCar", JSON.stringify(shopCar));
        if (this.type == "1" && this.isEdit) {
          let data = {
            deliveryName: this.form.name,
            deliveryCityName: this.form.city && this.form.city.join("/"),
            deliveryAddress: this.form.address,
            deliveryMobile: this.form.phone,
          };
          if (
              !Object.keys(data).every((key) => {
                return !!data[key];
              })
          ) {
            alert("请完整填写表单");
            return;
          }
          data.id = this.$store.state.userInfo.id;
          data.deliveryCity = "";
          this.$api.saveClientInfo(data).then((res) => {
            if (res.code === 0) {
              this.$api
                  .getClientInfo({
                    id: this.$store.state.userInfo.id,
                  })
                  .then((res) => {
                    if (res.code === 0) {
                      this.$store.commit("setUserInfo", res.data);
                    }
                  });
              this.isEdit = !this.isEdit;
            }
          });
        } else if (this.type == "2") {
          window.localStorage.setItem("facilitatorId", this.activeMerchants.id);
        }
        this.$router.push({
          name: "PaymentPage",
          query: {cartype: this.cartype},
        });
      } else {
        this.$message.warning("请先补全收货地址");
      }
    },
    calculate(key, shopIdx, idx) {
      if (key === "sub" && this.shopList[shopIdx].attrList[idx].num > 1) {
        this.shopList[shopIdx].attrList[idx].num -= 1;
      } else if (
          key === "add" &&
          this.shopList[shopIdx].attrList[idx].num >= 0
      ) {
        this.shopList[shopIdx].attrList[idx].num += 1;
        this.total += this.shopList[shopIdx].attrList[idx].price;
      }
    },
    calculateInner(key, shopIdx, idx, idxc) {
      if (key === "sub" && this.shopList[shopIdx].attrList[idx].innerList[idxc].num > 1) {
        this.shopList[shopIdx].attrList[idx].innerList[idxc].num -= 1;
      } else if (
          key === "add" &&
          this.shopList[shopIdx].attrList[idx].innerList[idxc].num >= 0
      ) {
        this.shopList[shopIdx].attrList[idx].innerList[idxc].num += 1;
        this.total += this.shopList[shopIdx].attrList[idx].innerList[idxc].price;
      }
    },
    addAddress() {
      let id = "";
      for (var i = 0; i < 3; i++) {
        id += Math.floor(Math.random() * 10);
      }
      this.address.push({
        id: id,
        address: "",
        name: "",
        city: "",
        phone: "",
      });
      this.form = this.address[this.address.length - 1];
      this.isEdit = true;
    },
    addressClick() {
      if (this.isEdit) {
        let data = {
          deliveryName: this.form.name,
          deliveryCityName: this.form.city && this.form.city.join("/"),
          deliveryAddress: this.form.address,
          deliveryMobile: this.form.phone,
        };
        if (
            !Object.keys(data).every((key) => {
              return !!data[key];
            })
        ) {
          alert("请完整填写表单");
          return;
        }
        data.id = this.$store.state.userInfo.id;
        data.deliveryCity = "";
        this.$api.saveClientInfo(data).then((res) => {
          if (res.code === 0) {
            this.$api
                .getClientInfo({
                  id: this.$store.state.userInfo.id,
                })
                .then((res) => {
                  if (res.code === 0) {
                    this.$store.commit("setUserInfo", res.data);
                  }
                });
            this.isEdit = !this.isEdit;
          }
        });
      } else {
        this.isEdit = !this.isEdit;
      }
    },
    getCity() {
      this.$api.getAddressAll().then((res) => {
        if (res.code === 0) {
          this.merchantsCityList = res.data.map((item) => {
            let result = {
              id: item.id,
              remark: item.remark,
              label: item.cityName,
              level: item.level,
              code: item.cityCode,
              parentId: item.parentId,
            };
            if (item.listAddress) {
              result.children = item.listAddress.map((item) => {
                return {
                  id: item.id,
                  label: item.cityName,
                  level: item.level,
                  code: item.cityCode,
                  parentId: item.parentId,
                };
              });
            }
            return result;
          });
          this.merchantsCityList.sort((a, b) => {
            const remarkA = a.remark.toUpperCase(); // 忽略大小写
            const remarkB = b.remark.toUpperCase(); // 忽略大小写
            if (remarkA < remarkB) {
              return -1;
            }
            if (remarkA > remarkB) {
              return 1;
            }
            // name 必须相等
            return 0;
          });
          console.log(this.merchantsCityList)
          this.cascaderVal = this.merchantsCityList[0].children[0].id;
          this.getFacilitatorList(this.cascaderVal);
        }
      });
    },
    getFacilitatorList(id) {
      if (typeof id !== "string") {
        id = id[1];
      }
      this.$api
          .getFacilitatorList({
            cityid: id,
          })
          .then((res) => {
            if (res.code === 0) {
              this.merchantsList = [];
              this.activeMerchants = {};
              this.merchantsList = res.data.map((item) => {
                return {
                  id: item.id,
                  name: item.facilitatorName,
                  region: item.facilitatorAddressName,
                  city: item.providerCityName,
                  address: item.facilitatorPlace,
                  type: 0,
                  tel: item.phone,
                  week: item.workingHours,
                  timer: item.workingTime,
                };
              });
              if (this.merchantsList.length) {
                this.activeMerchants = this.merchantsList[0];
              }
            }
          });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/SettlementPage.scss";
</style>
