var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("settlement.title"))),
          ]),
          _c(
            "div",
            {
              staticClass: "type-box",
              style: { width: this.$i18n.locale === "zh" ? "400px" : "200px" },
            },
            [
              _c(
                "div",
                {
                  class: { active: _vm.type === 1 },
                  on: {
                    click: function ($event) {
                      _vm.type = 1
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("settlement.button_send_me")))]
              ),
              this.$i18n.locale === "zh"
                ? _c(
                    "div",
                    {
                      class: { active: _vm.type === 2 },
                      on: {
                        click: function ($event) {
                          _vm.type = 2
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settlement.button_send_shop")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "price-box" }, [
            _vm._v(" " + _vm._s(_vm.$t("settlement.total_title")) + " "),
            _c("span", { staticStyle: { "font-family": "'ELEMENT-ICONS'" } }, [
              _vm._v(_vm._s(_vm.total)),
            ]),
            _vm._v(" " + _vm._s(_vm.$t("settlement.total_title_end")) + " "),
          ]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "shop-box" }, [
            _c(
              "div",
              { staticClass: "shop-list" },
              [
                _c("h2", [_vm._v(_vm._s(_vm.$t("settlement.goods")))]),
                _vm._l(_vm.shopList, function (item, shopIdx) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      !_vm.isRim
                        ? [
                            _vm._l(item.attrList, function (attr, idx) {
                              return [
                                attr.active
                                  ? _c(
                                      "div",
                                      {
                                        key: attr.id,
                                        staticClass: "shop-item",
                                      },
                                      [
                                        _c("img", { attrs: { src: item.img } }),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.calculate(
                                                    "sub",
                                                    shopIdx,
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("-")]
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "#fff" } },
                                            [_vm._v("x" + _vm._s(attr.num))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.calculate(
                                                    "add",
                                                    shopIdx,
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        : _vm._e(),
                      _vm.isRim
                        ? [
                            item.attrList.length <= 2
                              ? [
                                  _vm._l(item.attrList, function (attr, idx) {
                                    return [
                                      attr.active
                                        ? _c(
                                            "div",
                                            {
                                              key: attr.id,
                                              staticClass: "shop-item",
                                            },
                                            [
                                              _c("img", {
                                                attrs: { src: item.img },
                                              }),
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.calculate(
                                                          "sub",
                                                          shopIdx,
                                                          idx
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("-")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#fff",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "x" + _vm._s(attr.num)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.calculate(
                                                          "add",
                                                          shopIdx,
                                                          idx
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("+")]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ]
                              : _vm._e(),
                            item.attrList.length > 2
                              ? [
                                  _vm._l(item.attrList, function (attr, idx) {
                                    return [
                                      _vm._l(
                                        attr.innerList,
                                        function (attrInner, idxc) {
                                          return [
                                            attrInner.active
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: attrInner.id,
                                                    staticClass: "shop-item",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: { src: item.img },
                                                    }),
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.calculateInner(
                                                                "sub",
                                                                shopIdx,
                                                                idx,
                                                                idxc
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("-")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "x" +
                                                              _vm._s(
                                                                attrInner.num
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.calculateInner(
                                                                "add",
                                                                shopIdx,
                                                                idx,
                                                                idxc
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ]
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
            _c("div", { staticClass: "shop-text" }, [
              _c("div", { staticStyle: { "font-size": "18px" } }, [
                _c("img", {
                  attrs: { src: "https://www.achencybe.com/img/pc/logo.png" },
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settlement.shop_text.text_1")) +
                    _vm._s(
                      _vm.titleType === "锻造轮辋"
                        ? "Forged Wheel"
                        : _vm.titleType
                    ) +
                    _vm._s(_vm.$t("settlement.shop_text.text_2")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "border" }, [
                _c("div", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("settlement.shop_text.text_3")) + " "
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "CarEPlusPage" })
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("settlement.shop_text.text_4")))]
                  ),
                ]),
                _c("div", { staticStyle: { "margin-top": "20px" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("settlement.shop_text.text_5")) + " "
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "ClubPage" })
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("settlement.shop_text.text_6")))]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "border",
                  staticStyle: { "padding-top": "50px" },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("settlement.border.text_1"))),
                  _c("span", [
                    _vm._v(_vm._s(_vm.stringFormat(_vm.clientAdderss))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "time-box",
                      staticStyle: {
                        "font-size": "18px",
                        "line-height": "32px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settlement.border.text_2")) +
                          _vm._s(_vm.$route.query.completionTime) +
                          _vm._s(_vm.$t("settlement.border.text_3")) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("settlement.border.text_4"))),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.$t("settlement.border.text_5")) + " "
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "address-box" },
            [
              _vm.type === 1
                ? [
                    _c("div", { staticClass: "address-title" }, [
                      _vm._v(_vm._s(_vm.$t("settlement.address_box.title"))),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "address-con" }, [
                        _c("div", { staticClass: "address-left" }, [
                          _c("div", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settlement.address_box.title_placeholder"
                                )
                              )
                            ),
                          ]),
                          !_vm.address.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "ipt-box",
                                  staticStyle: { cursor: "pointer" },
                                  on: { click: _vm.addAddress },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settlement.address_box.add_address"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.address.length
                            ? _c(
                                "button",
                                {
                                  staticClass: "address-btn",
                                  on: { click: _vm.addressClick },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isEdit
                                          ? this.$t(
                                              "settlement.address_box.save_address"
                                            )
                                          : this.$t(
                                              "settlement.address_box.edit_address"
                                            )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "tips-box" }, [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "20px" } },
                              [_vm._v(_vm._s(_vm.$t("settlement.tips.text_1")))]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("settlement.tips.text_2")) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("settlement.tips.text_3")) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("settlement.tips.text_4"))),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "address-right" },
                          [
                            _vm.address.length
                              ? [
                                  _vm.isEdit
                                    ? [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.name,
                                              expression: "form.name",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "name",
                                            placeholder: this.$t(
                                              "settlement.address_placeholder.name"
                                            ),
                                          },
                                          domProps: { value: _vm.form.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("el-cascader", {
                                          attrs: {
                                            options: _vm.cityList,
                                            filterable: "",
                                            props: {
                                              value: "label",
                                            },
                                            placeholder: this.$t(
                                              "settlement.address_placeholder.city"
                                            ),
                                          },
                                          model: {
                                            value: _vm.form.city,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "city", $$v)
                                            },
                                            expression: "form.city",
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.address,
                                              expression: "form.address",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "address",
                                            placeholder: this.$t(
                                              "settlement.address_placeholder.address"
                                            ),
                                          },
                                          domProps: { value: _vm.form.address },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "address",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.phone,
                                              expression: "form.phone",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "phone",
                                            placeholder: this.$t(
                                              "settlement.address_placeholder.phone"
                                            ),
                                          },
                                          domProps: { value: _vm.form.phone },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : _vm._l(_vm.address, function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "ipt-box",
                                            staticStyle: {
                                              "margin-left": "-100px",
                                              "margin-top": "0px",
                                              width: "500px",
                                              cursor: "pointer",
                                              padding: "10px 20px 20px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.form = item
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "18px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      color: "#eee",
                                                      "font-weight": "100",
                                                      "text-align": "left",
                                                      "margin-bottom": "16px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "settlement.ipt_box.title"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                      "text-align": "left",
                                                      "line-height": "1.6",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                      "text-align": "left",
                                                      "line-height": "1.6",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.address) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                      "text-align": "left",
                                                      "line-height": "1.6",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.phone) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "-96px",
                                        color: "#535353",
                                        "font-weight": "100",
                                        "margin-top": "20px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "settlement.ipt_box.title_none"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "address-title" }, [
                      _vm._v(_vm._s(_vm.$t("settlement.shop.shop_title"))),
                    ]),
                    _c("div", [
                      _c("form", { attrs: { action: "1111" } }, [
                        _c("div", { staticClass: "address-con" }, [
                          _c(
                            "div",
                            { staticClass: "address-left" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "18px",
                                    "margin-bottom": "27px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("settlement.shop.shop_search")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("el-cascader", {
                                attrs: {
                                  options: _vm.merchantsCityList,
                                  filterable: "",
                                  props: {
                                    value: "id",
                                  },
                                  placeholder: this.$t("settlement.shop.city"),
                                },
                                on: { change: _vm.getFacilitatorList },
                                model: {
                                  value: _vm.cascaderVal,
                                  callback: function ($$v) {
                                    _vm.cascaderVal = $$v
                                  },
                                  expression: "cascaderVal",
                                },
                              }),
                              _c(
                                "el-scrollbar",
                                {
                                  staticStyle: {
                                    width: "373px",
                                    height: "420px",
                                  },
                                },
                                _vm._l(_vm.merchantsList, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "ipt-box ipt-checkbox",
                                      class: {
                                        "is-active":
                                          _vm.activeMerchants.id === item.id,
                                      },
                                      staticStyle: {
                                        width: "353px",
                                        cursor: "pointer",
                                        margin: "20px 0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.activeMerchants = item
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "font-size": "18px" },
                                        },
                                        [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "text-align": "left",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " ——" +
                                                  _vm._s(item.region) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "font-size": "18px" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.type === 0
                                                  ? _vm.$t(
                                                      "settlement.shop.support.free"
                                                    )
                                                  : _vm.$t(
                                                      "settlement.shop.support.pay"
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "address-right" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "line-height": "1.8",
                                  width: "380px",
                                  "font-size": "18px",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.activeMerchants.name)),
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.activeMerchants.address)),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "20px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.activeMerchants.city) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.activeMerchants.tel)),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.activeMerchants.week) + " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "20px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.activeMerchants.timer) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "1.4",
                                      "font-size": "16px",
                                      "margin-bottom": "20px",
                                      "font-weight": "100",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("settlement.shop.alert.text_1")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "1.4",
                                      "font-size": "16px",
                                      "font-weight": "100",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("settlement.shop.alert.text_2")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tips-box",
                                staticStyle: {
                                  "text-align": "left",
                                  width: "380px",
                                  "margin-top": "20px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "20px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("settlement.shop.alert.text_3")
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("settlement.shop.alert.text_4")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("settlement.shop.alert.text_5")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "buy-box" }, [
            _c("div", [_vm._v(_vm._s(_vm.$t("settlement.footer.title")))]),
            _c("button", { on: { click: _vm.toGo } }, [
              _vm._v(_vm._s(_vm.$t("settlement.footer.button"))),
            ]),
          ]),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }